<template>
    <div>
        <Preloader v-if="loader"/>
        <h4 class="">Абонементы</h4>

        <div class="filter__container">
            <div class="row mb-3 justify-content-between">
                <div class="input-group col-4">
                    <input type="search" class="form-control h20" v-model="search" id="search-input">
                    <span class="input-group-append">
                      <button @click="fetchData(search)" type="button" class="btn btn-default">Поиск</button>
                    </span>
                </div>
                <div class="col-4 text-right">
                    <button class="button_add" @click.prevent="$router.push({name: 'ProductCreate'})">
                        Добавить абонемент
                    </button>
                </div>
            </div>
            <div class="input-group col-4">

            </div>
        </div>

        <div class="widget">
            <table class="table table-hover">
                <thead class="table_head">
                <tr>
                    <th>#</th>
                    <th>Локация</th>
                    <th>Тип</th>
                    <th>Название</th>
                    <th>Язык преподования</th>
                    <th>Кол-во</th>
                    <th>Продолж.</th>
                    <th>Статус</th>
                    <th>Действия</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="product in products" :key="product.id">
                    <td>{{ product.id }}</td>
                    <td>{{ product.location }}</td>
                    <td>{{ product.type }}</td>
                    <td>{{ product.title }}</td>
                    <td>{{ product.language }}</td>
                    <td>{{ product.lesson_count }}</td>
                    <td>{{ product.duration }} мин.</td>
                    <td>{{ getStatus(product.status) }}</td>

                    <td class="actions_container">
                        <router-link class="leads_table_action_link btn mr-2 btn-primary"
                                     :to="{name: 'ProductEdit', params:{ id: product.id }}">
                            <span class="glyphicon glyphicon-edit" />
                        </router-link>
<!--                      Убрали из-за бага с отображением удаленных абонементов -->
<!--                        <a class="btn mr-2 btn-danger" @click.prevent="deleteModal(product.id, product.title)">-->
<!--                            <span class="fa fa-trash-o delete"></span>-->
<!--                        </a>-->
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

import Preloader from '../../../components/Preloader';
import DeleteModal from "../../../components/Modal/DeleteModal";

export default {
    name: 'ProductList',
    components: {Preloader},
    data() {
        return {
            loader: false,
            search: null,
            products: null,
        };
    },
    computed: {},
    methods: {
        async fetchData(query = null) {
            try {
                this.loader = true;
                const {data} = await this.$axios.get('/crm/products', {params: {query}});
                this.products = data.data;
                this.pagination = data.meta;
                this.loader = false;
            } catch (ex) {
                this.loader = false;
                console.log('cant fetch data: ' + ex);
            }
        },
        getStatus(status) {
            return status ? 'Опубликованно' : 'Не опубликованно';
        },
        deleteModal(id, name) {
            this.$modal.show(
                DeleteModal,
                {
                    recordId: id,
                    recordName: name,
                    deleteHandler: this.deleteRecord,
                    callback: async () => {
                        await this.fetchData(this.search);
                    },
                }, { name: 'DeleteModal' },
            );
        },
        async deleteRecord(id) {
            try {
                const { data } = await this.$axios.delete(`crm/products/${id}`)
                this.$toasted.success('Запись удалена', { position: 'bottom-right' });
            } catch (e) {
                this.$toasted.error(e, {position: "bottom-right"})
            }
        }
    },
    created() {
        this.fetchData();
    },
};
</script>

<style lang='scss' scoped>
.btn .glyphicon {
    top: 0 !important;
}

.h20 {
  padding: 20px 16px;
}

.actions_container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.student {
    &__name {
        position: relative;
    }

    &__badge {
        position: absolute;
        top: 30px;

        img {
            width: 30px;
        }
    }
}
</style>