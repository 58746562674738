<template>
    <div class='content_pages-page'>
        <h4 class='page-title'>
            Файлы <i class='fa fa-file' aria-hidden='true'></i>
            <div class='col-4 text-right'>
                <button class="button_add" @click.prevent="uploadNewFile">
                    Загрузить файл
                </button>
                <form id="uploader" style="display: none">
                    <input ref="FileInput" type="file" name="new_file" @change="onFilePicked" />
                </form>
            </div>
        </h4>

        <div class='widget'>
            <table class='table table-hover' v-if="filesList">
                <thead class='table_head'>
                <tr>
                    <th>Дата загрузки</th>
                    <th>Тип файла</th>
                    <th>Полный путь</th>
                    <th class='text-center actions'>Действия</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for='fileObject in filesList' :key='fileObject.hashId'>
                    <td>{{ fileObject.createdAt }}</td>
                    <td>{{ fileObject.type }}</td>
                    <td>{{ fileObject.urlPath }}</td>
                    <td class="d-flex justify-content-center">
                        <a
                            class="btn btn-danger"
                            @click.prevent="deleteRow(fileObject.fileName)"
                        >
                            <span class="fa fa-trash-o delete"></span>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>

        </div>
    </div>
</template>

<script>

import DeleteModal from '@/components/Modal/DeleteModal';

export default {
    name: 'ContentPages',
    data() {
        return {
            filesList: null,
        };
    },
    watch: {},
    methods: {
        async updateRouter() {
            await this.$router.push({ query: this.routerParams });
            await this.fetchFilesList();
        },
        async uploadNewFile() {
            this.$refs.FileInput.click();
        },
        async onFilePicked(event) {
            try {
                const formData = new FormData();
                formData.append('new_file', event.target.files[0]);
                const response = await this.$axios.post('/api/uploadFile', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                if (response.success === true) {
                    this.$toasted.success('Файл успешно загружен', { position: 'bottom-right' });
                } else {
                    this.$toasted.error(response.message, { position: 'bottom-right' });
                }
                await this.fetchFilesList();
            } catch (ex) {
                console.log(ex);
                alert('Ошибка загрузки файла');
            }
        },
        async fetchFilesList() {
            try {
                const { data } = await this.$axios.get('api/uploadedFiles', {
                    params: {
                        ...this.$route.query,
                    },
                });
                if (data && data.items && data.items.length) {
                    this.filesList = data.items;
                }
                return null;
            } catch (e) {
                alert(e);
            }
        },
        deleteRow(name) {
            this.$modal.show(
                DeleteModal,
                {
                    recordId: name,
                    recordName: name,
                    title: 'Подтверждаете удаление файла?',
                    deleteHandler: this.deleteFile,
                }, { name: 'DeleteModal' },
            );
        },
        async deleteFile(recordId) {
            const response = await this.$axios.post('/api/deleteFile', { filename: recordId });
            if (response.success === true) {
                this.$toasted.success('Файл удален', {position: 'bottom-right'});
            }
            await this.fetchFilesList();
        }
    },
    async created() {
        await this.fetchFilesList();
    },
};
</script>

<style lang="scss" scoped>
.page-title {
    margin: 0 0 calc(1rem + 16px);

    .text-right {
        float: right;
    }
}

</style>
